import React from "react"
import classNames from "classnames"

export const Columns = ({
  children,
  rowReverse,
  vcentered,
  centered,
  tcentered,
  tmcentered,
}) => (
  <div
    className={classNames("columns is-multiline is-variable is-4-desktop", {
      "flex-row-reverse": rowReverse,
      "is-vcentered": vcentered,
      "is-centered": centered,
      "has-text-centered": tcentered,
      "has-text-centered-mobile": tmcentered,
    })}
  >
    {children}
  </div>
)

export const Column = ({
  children,
  is3,
  is4,
  is5,
  is6,
  is7,
  is9,
  is11,
  is12,
  offset1,
  offset2,
  offset3,
  centered,
  tmcentered,
  is4321,
  is3321,
  borderl,
  ind,
  shadow,
  is8,
  noPaddingBottom,
  primary,
  success,
  danger,
  warning,
  link,
}) => (
  <div
    key={ind}
    className={classNames("column", {
      "is-12": is12,
      "is-11": is11,
      "is-7": is7,
      "is-9": is9,
      "is-8": is8,
      "is-6": is6,
      "is-5": is5,
      "is-4": is4,
      "is-3": is3,
      "is-offset-1": offset1,
      "is-offset-2": offset2,
      "is-offset-3": offset3,
      "has-text-centered-desktop": centered,
      "has-text-centered-mobile": tmcentered,
      "is-3-widescreen is-4-desktop is-6-tablet is-12-mobile": is4321,
      "is-4-widescreen is-4-desktop is-6-tablet is-12-mobile": is3321,
      "border-l": borderl,
      "is-shadow": shadow,
      "pb-0": noPaddingBottom,
      "has-bg-0003": primary,
      "has-background-success-rgba": success,
      "has-background-danger-rgba": danger,
      "has-background-warning-rgba": warning,
      "has-background-link-rgba": link,
    })}
  >
    {children}
  </div>
)
