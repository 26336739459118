import React from "react"
import { SocialIcon } from "react-social-icons"

const Share = () => {
  return (
    <div className="has-text-centered-mobile is-flex justify-content-center flex-wrap">
      <div className="mr-05">
        <SocialIcon
          url="https://play.google.com/store/apps/details?id=uz.fanomed.www.twa&hl=ru&gl=US"
          network="google_play"
          target="_blank"
        />
      </div>
      <div className="mr-05">
        <SocialIcon
          url="https://www.facebook.com/FANOMED_clinics-107744934993038"
          target="_blank"
        />
      </div>
      <div className="mr-05">
        <SocialIcon
          url="https://www.instagram.com/fanomed_clinics/"
          target="_blank"
        />
      </div>
      <div className="mr-05">
        <SocialIcon
          url="https://t.me/fanomed_clinic"
          network="telegram"
          target="_blank"
        />
      </div>
    </div>
  )
}

export default Share
