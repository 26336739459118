import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Section } from "../components/CompoundComponents/Section"

import Share from "../components/Share"
import Language from "../components/language"

const FooterComponent = ({ intl }) => (
  <>
    <footer className="footer is-shadow">
      <div className="container">
        <Columns vcentered>
          <Column is4>
            <p className="is-size-7 mb-1">
              © {new Date().getFullYear()} FANOMED Clinics.{" "}
              {intl.formatMessage({ id: "footer.copyright" })}
              {/* {intl.formatMessage({ id: "footer.copyright" })} */}
              <br />
              Сайт разработан{" "}
              <a
                href="https://www.jahondev.uz/"
                target="_blank"
                className="has-text-link"
              >
                www.jahondev.uz
              </a>
            </p>
            <p className="is-size-7 mb-1">
              {intl.formatMessage({ id: "footer.privacy.desc" })}{" "}
              <Link
                to="/privacy"
                className="is-size-7 has-text-primary"
                activeClassName="is-active"
              >
                {intl.formatMessage({ id: "footer.privacy.title" })}
              </Link>
            </p>
          </Column>
          <Column is6>
            <Share />
          </Column>
          <Column is2 tmcentered>
            <Language />
          </Column>
        </Columns>
      </div>
    </footer>
  </>
)

export default injectIntl(FooterComponent)
