import React, { Component, createContext } from "react"
import { navigate } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faPhone,
  faMapMarkerAlt,
  faFileMedical,
  faGraduationCap,
  faBriefcaseMedical,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons"

import FooterComponent from "../components/Footer"
const ShoppingCart = createContext()
export const ShoppingConsumer = ShoppingCart.Consumer

library.add(
  fab,
  faPhone,
  faMapMarkerAlt,
  faFileMedical,
  faGraduationCap,
  faBriefcaseMedical,
  faHandshake
)
class Layout extends Component {
  constructor() {
    super()

    this.handleAddToCart = this.handleAddToCart.bind(this)
    this.handleRemoveCart = this.handleRemoveCart.bind(this)
    this.handleButton = this.handleButton.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleService = this.handleService.bind(this)
    this.handleSpecialty = this.handleSpecialty.bind(this)
    this.handleRemoveSpecialty = this.handleRemoveSpecialty.bind(this)
    this.handleStartDate = this.handleStartDate.bind(this)
    this.handleEndDate = this.handleEndDate.bind(this)
    this.handleQuantity = this.handleQuantity.bind(this)
    this.handleName = this.handleName.bind(this)
    this.handlePhone = this.handlePhone.bind(this)

    this.state = {
      shoppingStatus: false,
      handleAdd: this.handleAddToCart,
      handleRemove: this.handleRemoveCart,
      handleButton: this.handleButton,
      handleSuccess: this.handleSuccess,
      handleService: this.handleService,
      handleSpecialty: this.handleSpecialty,
      handleRemoveSpecialty: this.handleRemoveSpecialty,
      handleStartDate: this.handleStartDate,
      handleEndDate: this.handleEndDate,
      handleQuantity: this.handleQuantity,
      handleName: this.handleName,
      handlePhone: this.handlePhone,
      cart: [],
      service: [],
      specialty: [],
      startDate: "",
      endDate: "",
      quantity: "",
      name: "",
      phone: "",
    }
  }

  handleAddToCart(data) {
    const val = this.props.intl.formatMessage({
      id: `products.${data}.title`,
    })
    this.setState(prevState => ({
      cart: [...prevState.cart, val],
    }))
  }

  handleService(data) {
    // const val = this.props.intl.formatMessage({
    //   id: `header.dropdown_item_4.${data}`,
    // })
    this.setState(prevState => ({
      service: [...prevState.service, { name: data.val, id: data.id }],
    }))
  }

  handleSpecialty(data) {
    // const val = this.props.intl.formatMessage({
    //   id: `header.dropdown_item_4.${data}`,
    // })

    this.setState(prevState => ({
      specialty: [...prevState.specialty, { name: data.val, id: data.id }],
    }))
  }

  handleQuantity(data) {
    this.setState({
      quantity: data,
    })
  }

  handleName(data) {
    this.setState({
      name: data,
    })
  }

  handlePhone(data) {
    this.setState({
      phone: data,
    })
  }

  handleStartDate(data) {
    this.setState({
      startDate: data,
    })
  }

  handleEndDate(data) {
    this.setState({
      endDate: data,
    })
  }

  handleRemoveCart(data) {
    this.setState({
      service: this.state.service.filter(function(item) {
        return item.id !== data
      }),
    })
  }

  handleRemoveSpecialty(data) {
    this.setState({
      specialty: this.state.specialty.filter(function(item) {
        return item.id !== data
      }),
    })
  }

  handleButton() {
    this.setState({
      service: [],
      specialty: [],
      startDate: "",
      endDate: "",
      quantity: "",
      name: "",
      phone: "",
    })
  }

  handleSuccess() {
    navigate("/")
  }

  render() {
    return (
      <ShoppingCart.Provider value={this.state}>
        <main>{this.props.children}</main>
        <FooterComponent />
      </ShoppingCart.Provider>
    )
  }
}

export default injectIntl(Layout)
