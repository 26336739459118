import React, { Children } from "react"
import classNames from "classnames"

export const SectionTitle = ({
  children,
  centeredDesktop,
  tcentered,
  bottom,
  white,
  primary,
}) => (
  <h2
    className={classNames(
      "is-size-4 is-uppercase is-size-6-mobile has-text-weight-bold",
      {
        "has-text-centered-desktop": centeredDesktop,
        "has-text-centered": tcentered,
        "has-text-white": white,
        "has-text-primary": primary,
        "mb-2": bottom,
      }
    )}
  >
    {Children.toArray(children)}
  </h2>
)

export const SectionSubtitle = ({
  children,
  centeredDesktop,
  bold,
  bottom,
  white,
  tcentered,
}) => (
  <p
    className={classNames("my-1 has-text-weight-normal has-family-nunito", {
      "has-text-centered-desktop": centeredDesktop,
      "has-text-weight-bold": bold,
      "mb-2": bottom,
      "has-text-white": white,
      "has-text-centered": tcentered,
    })}
  >
    {Children.toArray(children)}
  </p>
)

export const Section = ({
  children,
  link,
  white,
  primary,
  noPaddingBottom,
  f6,
  medium,
}) => {
  return (
    <section
      id={link}
      className={classNames("section", {
        "has-background-white": white,
        "has-background-primary": primary,
        "pb-0": noPaddingBottom,
        "has-background-F6F8FF": f6,
        "is-medium": medium,
      })}
    >
      <div className="container">{children}</div>
    </section>
  )
}
