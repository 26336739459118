// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-bronchoscopy-js": () => import("./../../../src/pages/bronchoscopy.js" /* webpackChunkName: "component---src-pages-bronchoscopy-js" */),
  "component---src-pages-cardiology-js": () => import("./../../../src/pages/cardiology.js" /* webpackChunkName: "component---src-pages-cardiology-js" */),
  "component---src-pages-chief-js": () => import("./../../../src/pages/chief.js" /* webpackChunkName: "component---src-pages-chief-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dermatovenerology-js": () => import("./../../../src/pages/dermatovenerology.js" /* webpackChunkName: "component---src-pages-dermatovenerology-js" */),
  "component---src-pages-doctors-js": () => import("./../../../src/pages/doctors.js" /* webpackChunkName: "component---src-pages-doctors-js" */),
  "component---src-pages-functional-diagnostics-js": () => import("./../../../src/pages/functional_diagnostics.js" /* webpackChunkName: "component---src-pages-functional-diagnostics-js" */),
  "component---src-pages-general-surgery-js": () => import("./../../../src/pages/general_surgery.js" /* webpackChunkName: "component---src-pages-general-surgery-js" */),
  "component---src-pages-hospital-js": () => import("./../../../src/pages/hospital.js" /* webpackChunkName: "component---src-pages-hospital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-laboratory-js": () => import("./../../../src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */),
  "component---src-pages-mini-operations-js": () => import("./../../../src/pages/mini_operations.js" /* webpackChunkName: "component---src-pages-mini-operations-js" */),
  "component---src-pages-neurology-js": () => import("./../../../src/pages/neurology.js" /* webpackChunkName: "component---src-pages-neurology-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-obstetrics-and-gynecology-js": () => import("./../../../src/pages/obstetrics_and_gynecology.js" /* webpackChunkName: "component---src-pages-obstetrics-and-gynecology-js" */),
  "component---src-pages-oncology-js": () => import("./../../../src/pages/oncology.js" /* webpackChunkName: "component---src-pages-oncology-js" */),
  "component---src-pages-otorhinolaryngology-js": () => import("./../../../src/pages/otorhinolaryngology.js" /* webpackChunkName: "component---src-pages-otorhinolaryngology-js" */),
  "component---src-pages-physiotherapy-js": () => import("./../../../src/pages/physiotherapy.js" /* webpackChunkName: "component---src-pages-physiotherapy-js" */),
  "component---src-pages-polyclinic-js": () => import("./../../../src/pages/polyclinic.js" /* webpackChunkName: "component---src-pages-polyclinic-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-promotion-js": () => import("./../../../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */),
  "component---src-pages-radiography-js": () => import("./../../../src/pages/radiography.js" /* webpackChunkName: "component---src-pages-radiography-js" */),
  "component---src-pages-radiology-js": () => import("./../../../src/pages/radiology.js" /* webpackChunkName: "component---src-pages-radiology-js" */),
  "component---src-pages-resuscitation-js": () => import("./../../../src/pages/resuscitation.js" /* webpackChunkName: "component---src-pages-resuscitation-js" */),
  "component---src-pages-therapy-js": () => import("./../../../src/pages/therapy.js" /* webpackChunkName: "component---src-pages-therapy-js" */),
  "component---src-pages-thoracic-surgery-js": () => import("./../../../src/pages/thoracic_surgery.js" /* webpackChunkName: "component---src-pages-thoracic-surgery-js" */),
  "component---src-pages-traumatology-orthopedics-js": () => import("./../../../src/pages/traumatology_orthopedics.js" /* webpackChunkName: "component---src-pages-traumatology-orthopedics-js" */),
  "component---src-pages-ultrasound-js": () => import("./../../../src/pages/ultrasound.js" /* webpackChunkName: "component---src-pages-ultrasound-js" */),
  "component---src-pages-urology-js": () => import("./../../../src/pages/urology.js" /* webpackChunkName: "component---src-pages-urology-js" */),
  "component---src-pages-vascular-surgery-js": () => import("./../../../src/pages/vascular_surgery.js" /* webpackChunkName: "component---src-pages-vascular-surgery-js" */)
}

