import React from "react"
import ReactCountryFlag from "react-country-flag"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import classNames from "classnames"

const languageName = {
  uz: "UZ",
  ru: "RU",
}

const handleLang = lang => {
  changeLocale(lang)
}

const Language = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <button
            key={language}
            onClick={() => changeLocale(language)}
            className={classNames(
              "button is-outlined is-small has-text-weight-bold is-uppercase mr-05 mb-1",
              {
                "has-text-white": currentLocale === language,
                "has-text-dark": currentLocale !== language,
              }
            )}
          >
            <ReactCountryFlag
              countryCode={languageName[language]}
              svg
              style={{
                width: "2em",
                height: "2em",
              }}
              title={languageName[language]}
            />
          </button>
        ))
      }
    </IntlContextConsumer>
  )
}

export default Language
